<template>
  
    <banner :banner="banner" :caption="caption" :path="bannerPath"></banner>

    <section class="content-home">
        <div class="container-fluid border-bottom-red">
            <div class="row">
                <div class="col-2">
                    <div class="content-block">
	    				<img class="icon-red img-left" src="../../assets/images/berita-terbaru-w.svg" alt="icon">
					</div>
                </div>

                <div class="col-10">
                    <div class="content-block">
                        <h3>Berita <span class="alternate">Terbaru</span></h3>

                        <div class="row">
                            <div class="col-lg-6">
                                <div v-if="jenis.length > 0">
                                    <div class="berita">
                                        <div class="row">
                                            <div class="col-12">
                                                <article class="berita-post-single">
                                                    <div class="post-contents">
                                                        <div class="post-title">
                                                            <h6>
                                                                <router-link :to="'/berita/baca/' + idberita">
                                                                    {{judul}}
                                                                </router-link>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>

                                        <div class="row mt-3">
                                            <div class="col-lg-6">
                                                <video width="300" height="auto" class="img-fluid mb-3" controls>
                                                    <source :src="coverPath + cover">
                                                </video>
                                            </div>

                                            <div class="col-lg-6">
                                                <span class="post-date">
                                                    {{waktu}}
                                                </span>
                                                <p v-html="berita.substr(0, 200)"></p>
                                            </div>

                                            <p v-html="berita.substr(200, 500)"></p>                                    
                                        </div>

                                        <div class="more text-end">
											<a>
                                                <router-link :to="'/berita/baca/' + idberita">
                                                    baca selengkapnya <i class="zmdi zmdi-caret-right-circle"></i>
                                                </router-link>
                                            </a>
										</div>
                                    </div>
                                </div>

                                <div v-else>
                                    <div class="berita">
                                        <div class="row">
                                            <div class="col-12">
                                                <article class="berita-post-single">
                                                    <div class="post-contents">
                                                        <div class="post-title">
                                                            <h6>
                                                                <router-link :to="'/berita/baca/' + idberita">
                                                                    {{judul}}
                                                                </router-link>
                                                            </h6>
                                                        </div>
                                                    </div>
                                                </article>
                                            </div>
                                        </div>

                                        <div class="row mt-3">
                                            <div class="col-lg-6">
                                                <img :src="coverPath + cover" class="img-fluid post-thumb mb-3" alt="cover-image" width="175" height="117">
                                            </div>

                                            <div class="col-lg-6">
                                                <span class="post-date">
                                                    {{waktu}}
                                                </span>
                                                <p v-html="berita.substr(0, 200)"></p>
                                            </div>

                                            <p v-html="berita.substr(200, 500)"></p>                                    
                                        </div>

                                        <div class="more text-end">
											<a>
                                                <router-link :to="'/berita/baca/' + idberita">
                                                    baca selengkapnya <i class="zmdi zmdi-caret-right-circle"></i>
                                                </router-link>
                                            </a>
										</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <template v-for="data in dataBerita" :key="data.idberita">
                                    <div v-if="data.jenis === 'video'">
                                        <div class="berita">
                                            <article class="berita-post-alt">
                                                <div class="row">
                                                    <div class="col-3">
                                                        <video width="300" height="auto" class="img-berita" controls>
                                                            <source :src="coverPath + cover">
                                                        </video>
                                                    </div>

                                                    <div class="col-9 post-contents">
                                                        <div class="post-date">
                                                            <span>
                                                                <timeago :datetime="data.waktu"/>
                                                            </span>
                                                        </div>
                                                        <div class="post-title">
                                                            <h6>
                                                                <a>
                                                                    <router-link :to="'/berita/baca/' + data.idberita">
                                                                        {{data.judul}}
                                                                    </router-link>
                                                                </a>
                                                            </h6>
                                                        </div>

                                                        <div class="post-exerpts">
                                                            <p v-html="data.berita.substr(0, 100)"></p>
                                                        </div>

                                                        <div class="more text-end">
                                                            <a>
                                                                <router-link :to="'/berita/baca/' + data.idberita">
                                                                    baca selengkapnya <i class="zmdi zmdi-caret-right-circle"></i>
                                                                </router-link>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    </div>

                                    <div v-else>
                                        <div class="berita">
                                            <article class="berita-post-alt">
                                                <div class="row">
                                                    <div class="col-3">
                                                        <img :src="coverPath + data.cover" :alt="data.cover" class="img-berita">
                                                    </div>

                                                    <div class="col-9 post-contents">
                                                        <div class="post-date">
                                                            <span>
                                                                <timeago :datetime="data.waktu"/>
                                                            </span>
                                                        </div>
                                                        <div class="post-title">
                                                            <h6>
                                                                <a>
                                                                    <router-link :to="'/berita/baca/' + data.idberita">
                                                                        {{data.judul}}
                                                                    </router-link>
                                                                </a>
                                                            </h6>
                                                        </div>

                                                        <div class="post-exerpts">
                                                            <p v-html="data.berita.substr(0, 100)"></p>
                                                        </div>

                                                        <div class="more text-end">
                                                            <a>
                                                                <router-link :to="'/berita/baca/' + data.idberita">
                                                                    baca selengkapnya <i class="zmdi zmdi-caret-right-circle"></i>
                                                                </router-link>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    </div>
                                </template>

                                <!-- <v-pagination
                                    v-model="total"
                                    :pages="currentPage"
                                    :range-size="1"
                                    active-color="#DCEDFF"
                                    @update:modelValue="updateHandler"
                                /> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

</template>

<script>

import Banner from '../banner/banner.vue'
import axios from 'axios'
// import VPagination from "@hennge/vue3-pagination";
// import "@hennge/vue3-pagination/dist/vue3-pagination.css";

export default {
    name: 'pageBerita',
    components: {
        Banner,
        // VPagination
    },
    data () {
        return {
            // bannerPath: 'http://localhost:8080/assets/banner/',
            bannerPath: 'https://api.plnbag.co.id/assets/banner/',
            banner: '',
            caption: '',
            judul: '',
            berita: '',
            waktu: '',
            cover: '',
            idberita: '',
            // coverPath: 'http://localhost:8080/assets/berita/',
            coverPath: 'https://api.plnbag.co.id/assets/berita/',
            jenis: '',
            dataBerita: [],
            total: 0,
            currentPage: 1,
            perPage: 5,
        }
    },
    mounted () {
        this.loadBanner()
        this.loadHeadline()
        this.loadBerita()
    },
    methods: {
        loadBanner () {
            axios.get('berita_banner')
                .then((res) => {
                    this.banner = res.data.data.banner
                    this.caption = res.data.data.caption_indo
                })
        },

        loadHeadline () {
            axios.get('berita_headline')
                .then((res) => {
                    this.judul = res.data.data.judul
                    this.berita = res.data.data.berita
                    this.waktu = res.data.data.waktu
                    this.cover = res.data.data.cover
                    this.idberita = res.data.data.idberita
                    this.jenis = res.data.data.jenis
                })
        },

        loadBerita () {
            axios.get('berita_list')
                .then((res) => {
                    this.dataBerita = res.data.data
                    this.total = res.data.count
                })
        },

        updateHandler () {
            this.perPage = this.dataBerita.slice(10)
        }
    }
}
</script>

<style scoped>
    @import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700|Montserrat:300,400,500,700");

    h1, h2, h3, h4, h5, h6 {
        font-family: "Montserrat", sans-serif;
        color: #222222;
        font-weight: 600;
    }

    h1 {
        font-size: 2.8rem;
    }

    h2 {
        font-size: 2.5rem;
    }

    h3 {
        font-size: 2.25rem;
    }

    h4 {
        font-size: 1.6875rem;
    }

    h5 {
        font-size: 1.375rem;
    }

    h6 {
        font-size: 1.25rem;
    }

    p, a, li, blockquote, label {
        font-size: 1rem;
        line-height: 26px;
        color: #848484;
        margin-bottom: 0;
    }
    
    .img-left {
        width: 85px;
        height: auto;
    }

    @media screen and (max-width: 767px) {
        .img-left {
            width: 45px;
            height: auto;
        }
    }

    .content-home {
        position: relative;
    }

    .content-home .content-block {
        padding: 25px 0 0;
    }

    .content-home .content-block h3 {
        color: #0158a7;
        text-transform: uppercase;
        font-weight: 600;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block span.title-w {
        color: #fff;
        text-transform: uppercase;
        font-weight: 600;
        margin-left: 10px;
        margin-bottom: 15px;
        font-size: 17px;
    }

    .content-home .content-block p.text-w {
        color: #fff;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .content-block p {
        color: #404041;
        margin-bottom: 20px;
        font-weight: 200;
        text-align: justify;
    }

    .content-home .image-block {
        position: absolute;
        right: 5%;
        bottom: 0;
    }

    @media (max-width: 767px) {
        .content-home .image-block {
            display: none;
        }
    }

    .border-bottom-red {
        border-bottom: 5px solid #ea2e23;
    }

    .icon-red {
        background-color: #ea2e23;
        border-radius: 5px;
        padding: 10px;
        float: right;
    }

    @media (max-width: 767px) {
        .icon-red {
            float: none;
        }
    }

    span.alternate {
        color: #ea2e23;
        font-weight: 800;
    }

    .shadow, .speakers.white .speaker-item:hover,
    .schedule.two .schedule-contents, .sponsors .block .image-block:hover,
    ul.pagination li a:hover, ul.pagination li a:focus, ul.pagination .active a,
    /*.berita .berita-post:hover,*/ .testimonial .testimonial-item .comment:hover {
        box-shadow: 0px 0px 30px 0px rgba(11, 29, 66, 0.15);
    }

    .berita .berita-post,
    .berita .berita-post-single {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        margin-bottom: 15px;
    }

    .berita .berita-post-alt {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -moz-flex;
        display: -webkit-flex;
        display: flex;
        margin-bottom: 25px;
        padding-bottom: 20px;
        border-bottom: 2px solid red;
    }

    .berita .berita-post .post-thumb,
    .berita .berita-post-alt .post-thumb,
    .berita .berita-post-single .post-thumb {
    -ms-flex-preferred-size: 30%;
        flex-basis: 30%;
    }

    .berita .berita-post .post-thumb img {
        /*width: 100%;*/
        position: absolute;
        border-radius: 15px;
        /*clip: rect(0px, 100px, 100px, 0px);*/
    }

    .post-thumb img {
        position: absolute;
        border-radius: 15px;
        border: 2px solid #ea2e23;
    }

    .berita .berita-post-single img.post-thumb {
        border-radius: 15px;
        margin-right:15px;
        float: left;
        height: 115px;
        width: 175px;
    }

    .armada .armada-post .post-thumb img {
        position: absolute;
        border-radius: 15px;
        border: 2px solid #fff;
    }

    .berita .berita-post .post-contents,
    .berita .berita-post-alt .post-contents,
    .berita .berita-post-single .post-contents,
    .armada .armada-post .post-contents {
        /*-ms-flex-preferred-size: 50%;
            flex-basis: 50%;*/
        padding: 0px 10px;
        position: relative;
        /*border-right: 1px solid;
        border-bottom: 1px solid;
        border-color: #e5e5e5;*/
    }

    .berita .berita-post-alt .post-contents .post-date span {
        font-weight: 600;
        position: relative;
        font-size: 13px;
        color: #3a3a3a;
    }

    span.post-date {
        font-weight: 600;
        position: relative;
        font-size: 15px;
        color: #ea2e23;
    }

    .berita .berita-post .post-contents .post-title h6,
    .berita .berita-post-alt .post-contents .post-title h6,
    .berita .berita-post-single .post-contents .post-title h6 {
        margin-bottom: 15px;
        /*margin-top: 5px;*/
        line-height: 15px;
    }

    .berita .berita-post .post-contents .post-title h6 a {
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        font-size: 13px;
        color: #0158a7;
        line-height: 5px;
    }

    .berita .berita-post-alt .post-contents .post-title h6 a {
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        font-size: 13px;
        color: #ea2e23;
        line-height: 5px;
    }

    .berita .berita-post-single .post-contents .post-title h6 a {
        text-transform: uppercase;
        font-weight: 600;
        position: relative;
        font-size: 21px;
        color: #ea2e23;
        line-height: 21px;
    }

    a {
        text-decoration: none;
        color: #ea2e23;
    }

    .img-berita {
        width: 125px;
        height: auto;
        position: relative;
        border-radius: 15px;
        border: 2px solid #ea2e23;
    }

    @media screen and (max-width: 767px) {
        .img-berita {
            width: 55px;
            height: auto;
        }
    }

    .berita .berita-post .post-contents .post-exerpts p,
    .berita .berita-post-alt .post-contents .post-exerpts p {
        font-size: 12px;
        line-height: 15px;
    }

    .berita .berita-post-single .post-contents .post-exerpts p {
        font-size: 15px;
        line-height: 18px;
    }

</style>